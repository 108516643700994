exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-epilacja-laserowa-js": () => import("./../../../src/pages/epilacja-laserowa.js" /* webpackChunkName: "component---src-pages-epilacja-laserowa-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-strefa-fitness-js": () => import("./../../../src/pages/strefa-fitness.js" /* webpackChunkName: "component---src-pages-strefa-fitness-js" */),
  "component---src-pages-strefa-fitness-rollmasazer-z-ir-js": () => import("./../../../src/pages/strefa-fitness/rollmasazer-z-ir.js" /* webpackChunkName: "component---src-pages-strefa-fitness-rollmasazer-z-ir-js" */),
  "component---src-pages-strefa-fitness-rower-poziomowy-js": () => import("./../../../src/pages/strefa-fitness/rower-poziomowy.js" /* webpackChunkName: "component---src-pages-strefa-fitness-rower-poziomowy-js" */),
  "component---src-pages-strefa-fitness-swan-orbitrek-ir-js": () => import("./../../../src/pages/strefa-fitness/swan-orbitrek-ir.js" /* webpackChunkName: "component---src-pages-strefa-fitness-swan-orbitrek-ir-js" */),
  "component---src-pages-strefa-fitness-vacu-bieznia-ir-professional-js": () => import("./../../../src/pages/strefa-fitness/vacu-bieznia-ir-professional.js" /* webpackChunkName: "component---src-pages-strefa-fitness-vacu-bieznia-ir-professional-js" */),
  "component---src-pages-voucher-js": () => import("./../../../src/pages/voucher.js" /* webpackChunkName: "component---src-pages-voucher-js" */),
  "component---src-pages-zabiegi-na-cialo-elektrostymulacja-js": () => import("./../../../src/pages/zabiegi-na-cialo/elektrostymulacja.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-elektrostymulacja-js" */),
  "component---src-pages-zabiegi-na-cialo-endermomasaz-js": () => import("./../../../src/pages/zabiegi-na-cialo/endermomasaz.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-endermomasaz-js" */),
  "component---src-pages-zabiegi-na-cialo-epilacja-laserowa-js": () => import("./../../../src/pages/zabiegi-na-cialo/epilacja-laserowa.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-epilacja-laserowa-js" */),
  "component---src-pages-zabiegi-na-cialo-fala-rf-js": () => import("./../../../src/pages/zabiegi-na-cialo/fala-rf.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-fala-rf-js" */),
  "component---src-pages-zabiegi-na-cialo-hifu-js": () => import("./../../../src/pages/zabiegi-na-cialo/hifu.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-hifu-js" */),
  "component---src-pages-zabiegi-na-cialo-kriolipoliza-js": () => import("./../../../src/pages/zabiegi-na-cialo/kriolipoliza.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-kriolipoliza-js" */),
  "component---src-pages-zabiegi-na-cialo-limfodrenaz-js": () => import("./../../../src/pages/zabiegi-na-cialo/limfodrenaz.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-limfodrenaz-js" */),
  "component---src-pages-zabiegi-na-cialo-lipolaser-js": () => import("./../../../src/pages/zabiegi-na-cialo/lipolaser.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-lipolaser-js" */),
  "component---src-pages-zabiegi-na-cialo-liposukcja-kawitacyjna-js": () => import("./../../../src/pages/zabiegi-na-cialo/liposukcja-kawitacyjna.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-liposukcja-kawitacyjna-js" */),
  "component---src-pages-zabiegi-na-cialo-oczyszczanie-wodorowe-js": () => import("./../../../src/pages/zabiegi-na-cialo/oczyszczanie-wodorowe.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-oczyszczanie-wodorowe-js" */),
  "component---src-pages-zabiegi-na-cialo-vacuum-masaz-js": () => import("./../../../src/pages/zabiegi-na-cialo/vacuum-masaz.js" /* webpackChunkName: "component---src-pages-zabiegi-na-cialo-vacuum-masaz-js" */),
  "component---src-pages-zabiegi-na-twarz-endermomasaz-js": () => import("./../../../src/pages/zabiegi-na-twarz/endermomasaz.js" /* webpackChunkName: "component---src-pages-zabiegi-na-twarz-endermomasaz-js" */),
  "component---src-pages-zabiegi-na-twarz-epilacja-laserowa-js": () => import("./../../../src/pages/zabiegi-na-twarz/epilacja-laserowa.js" /* webpackChunkName: "component---src-pages-zabiegi-na-twarz-epilacja-laserowa-js" */),
  "component---src-pages-zabiegi-na-twarz-fala-rf-js": () => import("./../../../src/pages/zabiegi-na-twarz/fala-rf.js" /* webpackChunkName: "component---src-pages-zabiegi-na-twarz-fala-rf-js" */),
  "component---src-pages-zabiegi-na-twarz-hifu-js": () => import("./../../../src/pages/zabiegi-na-twarz/hifu.js" /* webpackChunkName: "component---src-pages-zabiegi-na-twarz-hifu-js" */),
  "component---src-pages-zabiegi-na-twarz-oczyszczanie-wodorowe-js": () => import("./../../../src/pages/zabiegi-na-twarz/oczyszczanie-wodorowe.js" /* webpackChunkName: "component---src-pages-zabiegi-na-twarz-oczyszczanie-wodorowe-js" */),
  "component---src-pages-zabiegi-na-twarz-sonoforeza-js": () => import("./../../../src/pages/zabiegi-na-twarz/sonoforeza.js" /* webpackChunkName: "component---src-pages-zabiegi-na-twarz-sonoforeza-js" */)
}

